import * as THREE from 'three';
import {
  convertBufferGeometryTo3DVectorList,
  createLine2,
  getDistanceBetweenInfiniteLineAndVector,
  getPerpendicularVectorToVectors,
  getTranslatedVector,
  isPointsInOneLine,
  isVectorLeftSide,
  triangulateGeometryAndUpdate,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { C_FatLineContourMaterial, C_FloorMaterial } from '@/shared/materials';
import { FlatVector3 } from '@/models';
import { convertFlatVector3ToVectors } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import * as polyclip from 'polyclip-ts';
import { LineGeometry } from 'three-stdlib';
import { Line2 } from 'three/examples/jsm/lines/Line2';

export const generateSectionPoints = (
  sections: THREE.Mesh[],
  currentLineSections: THREE.Vector3[],
  width: number,
  previousLineSectionPoint: THREE.Vector3
): THREE.Vector3[] => {
  if (sections.length > 1) {
    const { normal } = getCenterLineParamsForRightAngle(
      currentLineSections[1],
      [previousLineSectionPoint, ...currentLineSections]
    );

    const startingPoints = [
      getTranslatedVector(
        getTranslatedVector(currentLineSections[0], -width / 2, normal),
        width / 2,
        getPerpendicularVectorToVectors(currentLineSections, true)
      ),
      getTranslatedVector(
        getTranslatedVector(currentLineSections[0], -width / 2, normal),
        width / 2,
        getPerpendicularVectorToVectors(currentLineSections, false)
      ),
    ];
    const endPoints = [
      getTranslatedVector(
        currentLineSections[1],
        width / 2,
        getPerpendicularVectorToVectors(currentLineSections, false)
      ),
      getTranslatedVector(
        currentLineSections[1],
        width / 2,
        getPerpendicularVectorToVectors(currentLineSections, true)
      ),
    ];
    return [...startingPoints, ...endPoints];
  }

  const startingPoints = [
    getTranslatedVector(
      currentLineSections[0],
      width / 2,
      getPerpendicularVectorToVectors(currentLineSections, true)
    ),
    getTranslatedVector(
      currentLineSections[0],
      width / 2,
      getPerpendicularVectorToVectors(currentLineSections, false)
    ),
  ];
  const endPoints = [
    getTranslatedVector(
      currentLineSections[1],
      width / 2,
      getPerpendicularVectorToVectors(currentLineSections, false)
    ),
    getTranslatedVector(
      currentLineSections[1],
      width / 2,
      getPerpendicularVectorToVectors(currentLineSections, true)
    ),
  ];
  return [...startingPoints, ...endPoints];
};

export const getCenterLineParamsForRightAngle = (
  point: THREE.Vector3,
  vectors: THREE.Vector3[]
) => {
  const lastCenterLineVectors = [
    vectors[vectors.length - 2],
    vectors[vectors.length - 3],
  ];

  const isCounterClockWise = isVectorLeftSide(
    lastCenterLineVectors[0],
    lastCenterLineVectors[1],
    point
  );

  const normal = getPerpendicularVectorToVectors(
    lastCenterLineVectors,
    isCounterClockWise
  );

  const distance = getDistanceBetweenInfiniteLineAndVector(
    new THREE.Line3(lastCenterLineVectors[0], lastCenterLineVectors[1]),
    point
  );
  return { normal, distance, isCounterClockWise };
};

export const updateSections = ({
  sections,
  previousCenterLineVectors,
  closeSection,
  width,
  currentCenterLineSection,
  contour,
  setContour,
  floor,
  setFloor,
  setClosedFloor,
  generateFatCenterLine,
}: {
  sections: THREE.Mesh[];
  previousCenterLineVectors: THREE.Vector3;
  currentCenterLineSection: THREE.Vector3[];
  closeSection: boolean;
  width: number;
  contour: Line2;
  setContour: (l: Line2) => void;
  floor: THREE.Mesh;
  setFloor: (f: THREE.Mesh) => void;
  setClosedFloor: (f: THREE.Mesh) => void;
  generateFatCenterLine?: () => void;
}) => {
  const floorSectionGeometry = !sections.length
    ? new THREE.ShapeGeometry()
    : sections[sections.length - 1].geometry.clone();
  const section = generateSectionPoints(
    sections,
    currentCenterLineSection,
    width,
    previousCenterLineVectors
  );
  floorSectionGeometry.setFromPoints(section);

  triangulateGeometryAndUpdate(floorSectionGeometry, section);

  if (!closeSection) {
    if (!sections.length) {
      sections.push(
        new THREE.Mesh(floorSectionGeometry, C_FloorMaterial.clone())
      );
    } else {
      sections[sections.length - 1].geometry = floorSectionGeometry;
    }
  } else {
    sections.push(
      new THREE.Mesh(floorSectionGeometry, C_FloorMaterial.clone())
    );
  }

  const points = sections.map((section) =>
    convertBufferGeometryTo3DVectorList(
      section.geometry,
      section.geometry.getAttribute('position').count
    )
  );

  const flatPoints = points.map((point) => [
    [point.map((p): [number, number] => [p.x, p.z])],
  ]);

  const union = polyclip.union(flatPoints[0], ...flatPoints.slice(1));

  const unionPoints = union[0][0].map(
    (point): FlatVector3 => [point[0], points[0][0].y, point[1]]
  );

  const material = C_FatLineContourMaterial.clone();
  material.color = new THREE.Color('#808285');
  if (!contour) {
    setContour(createLine2(unionPoints.flat(), material));
  } else {
    contour.geometry = new LineGeometry().setPositions(unionPoints.flat());
    contour.computeLineDistances();
  }
  if (!floor) {
    const mesh = new THREE.Mesh(
      new THREE.ShapeGeometry().setFromPoints(
        convertFlatVector3ToVectors(unionPoints)
      ),
      C_FloorMaterial.clone()
    );

    setFloor(mesh);
  } else {
    floor.geometry.setFromPoints(convertFlatVector3ToVectors(unionPoints));
    triangulateGeometryAndUpdate(
      floor.geometry,
      convertFlatVector3ToVectors(unionPoints)
    );
  }
  if (closeSection) {
    const points = convertBufferGeometryTo3DVectorList(
      floor.geometry,
      floor.geometry.getAttribute('position').count
    );
    const inLinePoints: THREE.Vector3[] = [];

    for (let i = 0; i < points.length - 1; i++) {
      const previousIndex = i === 0 ? points.length - 1 : i - 1;
      const nextIndex = i === points.length - 1 ? 0 : i + 1;

      const isInLine = isPointsInOneLine(
        [points[previousIndex].x, points[previousIndex].z],
        [points[i].x, points[i].z],
        [points[nextIndex].x, points[nextIndex].z]
      );
      !isInLine && inLinePoints.push(points[i]);
    }
    inLinePoints.push(inLinePoints[0]);

    const closedFloorGeometry = new THREE.BufferGeometry().setFromPoints(
      inLinePoints
    );
    triangulateGeometryAndUpdate(
      closedFloorGeometry,
      inLinePoints,
      'horizontal'
    );

    setClosedFloor(
      new THREE.Mesh(closedFloorGeometry, C_FloorMaterial.clone())
    );
  }

  generateFatCenterLine && generateFatCenterLine();
};
