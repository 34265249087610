import React, { useEffect, useMemo, useRef } from 'react';
import {
  C_FatLineBorderMaterial,
  C_FatLineSelectedBorderMaterial,
  C_WallMaterial,
  C_WallSelectedMaterial,
} from '@/shared/materials';
import {
  convertFlatVector3ToVectors,
  createGeometryFromVectorList,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import {
  CanvasActionsModes,
  FlatVector3,
  GridLineData,
  NodeType,
  PanelPlacementData,
  SelectedNodeSource,
  UserBuildingWall,
  WindowPlacementData,
} from '@/models';
import * as THREE from 'three';
import {
  createLine2,
  getCenterFromFlatVectorsArray,
  getXYZ,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  getIsEditToolsAvailable,
  getIsNodeHovered,
  getIsNodeIsolated,
  getIsNodeSelected,
  getIsSingleNodeSelected,
  isSingleNodeIsolated,
  selectOneNode,
  setExtrudeNode,
} from '@/store/slices/canvasBuildingSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { GenericChildSurface } from '@/models/building-nodes.model';
import ExtrudeDotHandler, {
  ExtrudeHandlerData,
} from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeDotHandler';
import {
  getIsFacadeDesignerModeEnabled,
  setMode,
} from '@/store/slices/canvasModesSlice';
import ViewerWindow from './ViewerWindow';
import { getDraggedWindowFromLibrary } from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { POLYGON_OFFSET_FACTOR_LEVELS } from '@/shared/constants';
import ViewerGridLine from '@/routes/dashboard/projects/project/UserBuilding/components/ViewerGridLine';
import { getMultiplyRate } from '@/store/slices/projectSlice';
import { useParams } from 'react-router';
import ViewerPanel from './ViewerPanel';
import { useGetAllPanelsQuery } from '@/store/apis/projectsApi';

interface WallProps extends GenericChildSurface {
  data: UserBuildingWall;
  blockGUID: string;
  isParentSelected: boolean;
  isParentHovered: boolean;
  isParentLocked: boolean;
  isProjectLocked: boolean;
  isolateMode: boolean;
  floorShapedCoordinates: FlatVector3[];
  storeyCenter: THREE.Vector3;

  storeyGUID: string;
  buildingGUID: string;
}

const Wall: React.FC<WallProps> = ({
  data,
  isParentSelected,
  isParentHovered,
  isParentLocked,
  isProjectLocked,
  isolateMode,
  storeyGUID,
  buildingGUID,
  blockGUID,
  floorShapedCoordinates,
  storeyCenter,
}) => {
  const dispatch = useAppDispatch();

  const { id } = useParams();
  const meshRef = useRef<THREE.Mesh>(null);
  const multiplyRate = useAppSelector(getMultiplyRate(id!));
  const isWallSelected = useAppSelector(getIsNodeSelected(data.guid));
  const isStoreySelected = useAppSelector(getIsNodeSelected(storeyGUID));
  const isWallIsolated = useAppSelector(getIsNodeIsolated(data.guid));
  const isSingleNodeSelected = useAppSelector(getIsSingleNodeSelected);
  const isOnlyWallIsolated = useAppSelector(isSingleNodeIsolated);
  const isDesignerMode = useAppSelector(getIsFacadeDesignerModeEnabled);
  const draggedWindowFromLibrary = useAppSelector(getDraggedWindowFromLibrary);
  const isWindowCardDragging = !!draggedWindowFromLibrary;
  const isEditToolsAvailable = useAppSelector(
    getIsEditToolsAvailable(buildingGUID)
  );

  const fetchedPanels = useGetAllPanelsQuery(id!).data;

  const wallHasPanels =
    data.wallPanels.filter(
      (panel) => !fetchedPanels?.find((fp) => fp.id === panel.panelId)?.isCorner
    ).length > 0;

  const isExtrudeAvailable =
    isWallSelected &&
    isSingleNodeSelected &&
    !data.userData?.isLocked &&
    !isParentLocked &&
    (!isWallIsolated || isolateMode) &&
    !isOnlyWallIsolated &&
    !isDesignerMode &&
    !isProjectLocked &&
    isEditToolsAvailable;

  const isNodeHovered = useAppSelector(getIsNodeHovered(data.guid));
  const isStoreyHovered = useAppSelector(getIsNodeHovered(storeyGUID));

  const extrudeHandlerData = useMemo((): ExtrudeHandlerData => {
    const center = getCenterFromFlatVectorsArray(data.points);

    return {
      defaultCenter: [center.x, center.y, center.z],
      node: { type: NodeType.Wall, guid: data.guid },
      extendAnchor: [storeyCenter.x, storeyCenter.y, storeyCenter.z],
      wallCoordinates: data.points,
    };
  }, [data, storeyCenter]);

  const handleSelectWallForExtrude = (node: ExtrudeHandlerData) => {
    dispatch(setExtrudeNode(node));
  };

  const isSelected = isParentSelected || isWallSelected;

  const wallCoordinates = useMemo(
    () => convertFlatVector3ToVectors(data.points),
    [data]
  );

  const wallGeometry = useMemo(
    () => createGeometryFromVectorList(wallCoordinates, 'vertical'),
    [wallCoordinates]
  );

  const wallMaterial = useMemo(() => {
    return C_WallMaterial.clone();
  }, [isSelected, isDesignerMode]);

  const edgeCoordinates = useMemo(() => {
    const coordinates: THREE.Vector3[][] = [];
    for (let i = 0; i < wallCoordinates.length - 1; i++) {
      coordinates.push([wallCoordinates[i], wallCoordinates[i + 1]]);
    }
    coordinates.push([
      wallCoordinates[wallCoordinates.length - 1],
      wallCoordinates[0],
    ]);
    return coordinates;
  }, [wallCoordinates]);

  const wallBorders = useMemo(() => {
    return edgeCoordinates.map((v, i) => {
      let material = C_FatLineBorderMaterial.clone();

      //color all borders if wall is selected
      if (
        isWallSelected ||
        isNodeHovered ||
        isStoreyHovered ||
        isStoreySelected ||
        isSelected
      ) {
        material = C_FatLineSelectedBorderMaterial.clone();
        material.polygonOffsetFactor = POLYGON_OFFSET_FACTOR_LEVELS.HIGH;
      }
      const line = createLine2([...getXYZ(v[0]), ...getXYZ(v[1])], material);

      const userData = {
        userData: {
          nodeType: NodeType.Storey,
          guid: storeyGUID,
          isLocked: isParentLocked,
          isSelected: isParentSelected,
        },
      };

      const isVerticalLine = v[0].z === v[1].z;

      return (
        <primitive
          object={line}
          key={i}
          {...(isVerticalLine ? userData : {})}
        />
      );
    });
  }, [
    edgeCoordinates,
    isParentSelected,
    isParentHovered,
    isWallSelected,
    isNodeHovered,
    isSelected,
    isDesignerMode,
  ]);

  const isDisplay = !isolateMode || (isolateMode && isWallIsolated);

  const iterateOverWindows = (windows?: WindowPlacementData[]) =>
    windows?.map((placement, i) => (
      <ViewerWindow
        scale={multiplyRate}
        placementData={placement}
        wallData={data.points}
        key={`placed_window-${data.guid}-${i}`}
      />
    ));

  const showPanels = (panels: PanelPlacementData[]) => {
    return panels.map((panel, i) => (
      <ViewerPanel
        scale={multiplyRate}
        placementData={panel}
        wallData={data}
        isSelected={isSelected}
        blockGUID={blockGUID}
        key={`placed_panel-${data.guid}-${i}`}
      />
    ));
  };

  const showGrids = (gridLines: GridLineData[]) =>
    gridLines.map((placement, i) => (
      <ViewerGridLine
        scale={multiplyRate}
        placementData={placement}
        wallData={data.points}
        key={`placed_grid-line-${data.guid}-${i}`}
      />
    ));

  const handleMouseUp = () => {
    if (isNodeHovered) {
      dispatch(
        selectOneNode({
          guid: data.guid,
          type: NodeType.Wall,
          userData: data.userData,
          source: SelectedNodeSource.Viewer,
        })
      );
      dispatch(setMode(CanvasActionsModes.facadeDesigner));
    }
  };

  useEffect(() => {
    if (isWindowCardDragging) {
      window.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isWindowCardDragging, isNodeHovered]);

  useEffect(() => {
    if (isSelected && !isDesignerMode) {
      (meshRef.current!.material as THREE.MeshBasicMaterial) =
        C_WallSelectedMaterial.clone();
    } else {
      (meshRef.current!.material as THREE.MeshBasicMaterial) =
        C_WallMaterial.clone();
    }
  }, [isSelected, isDesignerMode]);

  if (!isDisplay) return null;

  return (
    <>
      <mesh
        geometry={wallGeometry}
        material={wallMaterial}
        ref={meshRef}
        visible={!wallHasPanels}
        userData={{
          ...data.userData,
          nodeType: NodeType.Wall,
          guid: data.guid,
          isLocked: data.userData?.isLocked,
          isSelected,
          originalBuildingBlock: {
            guid: blockGUID,
          },
        }}
      >
        {wallBorders.map((b) => b)}
      </mesh>
      {!wallHasPanels && iterateOverWindows(data.windowPlacements)}
      {!wallHasPanels && showGrids(data.gridLines)}
      {wallHasPanels && showPanels(data.wallPanels)}
      {isExtrudeAvailable && (
        <ExtrudeDotHandler
          extrudeHandlerData={extrudeHandlerData}
          shapeCoordinates={floorShapedCoordinates}
          clickAction={() => handleSelectWallForExtrude(extrudeHandlerData)}
        />
      )}
    </>
  );
};

export default Wall;
