import React, { useMemo } from 'react';
import { useFetchIguQuery } from '@/store/apis/webCalcApi';
import IntusPopover from '@/shared/elements/Popover/Popover';
import { TooltipPlacement } from 'antd/lib/tooltip';

interface IguConfiguratorViewProps {
  iguIds: number[];
  paddingValue?: number;
  placement?: TooltipPlacement;
}

const IguConfiguratorView: React.FC<IguConfiguratorViewProps> = ({
  iguIds,
  paddingValue = 3,
  placement = 'leftTop',
}) => {
  const iguData = useFetchIguQuery().data!;

  const panelsIguData = useMemo(() => {
    return iguIds.reduce<{ [key: string]: number }>((acc, curr) => {
      if (!curr) return acc;
      const iguName = iguData.find((igu) => igu.id === curr)?.name;
      if (!iguName) return acc;
      return {
        ...acc,
        [iguName]: (acc[iguName] ?? 0) + 1,
      };
    }, {});
  }, [iguIds, iguData]);

  const isMultipleIGU = Object.keys(panelsIguData).length > 1;

  if (Object.keys(panelsIguData).length === 0) return null;

  const popoverContent = (
    <div
      className={'overflow-y-auto max-h-[350px] w-[300px] px-1 rounded-b-sm'}
    >
      {Object.keys(panelsIguData).map((iguName) => {
        return (
          <div
            key={iguName}
            className={`px-3 py-1 flex justify-between items-center text-xs leading-5 font-normal`}
          >
            <div className={'min-w-[212px] w-[212px] overflow-hidden'}>
              {iguName}
            </div>
            <div className={'px-6 w-16'}>{panelsIguData[iguName]}</div>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="font-light leading-5 mb-0.5">
      <div className={`pb-1.5 px-${paddingValue}`}>IGU configuration</div>
      <IntusPopover
        placement={placement}
        content={popoverContent}
        //disable ability to open when only one igu
        open={!isMultipleIGU ? false : undefined}
      >
        <div
          className={`px-${paddingValue} py-1 ${isMultipleIGU ? 'hover:bg-light-gray-10 cursor-pointer' : ''}`}
        >
          <div className={'text-xs leading-5 font-normal'}>
            {isMultipleIGU ? 'Multiple' : Object.keys(panelsIguData)[0]}
          </div>
        </div>
      </IntusPopover>
    </div>
  );
};

export default IguConfiguratorView;
