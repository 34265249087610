import { Scene, Mesh, Group, Object3D } from 'three';

export const findObjectByGuid = (scene: Scene, value: string) => {
  const foundObject: Object3D[] = [];
  scene.traverse((object) => {
    if (object.userData.guid === value && !foundObject.length) {
      foundObject.push(object);
    }
  });

  return foundObject[0];
};

export const scaleMesh = (mesh: Mesh | Group, scaleValue: number) =>
  mesh.scale.set(scaleValue, scaleValue, scaleValue);
