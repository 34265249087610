import React, { useMemo } from 'react';
import { NodeType, SelectedNodeSource, UserBuildingSurface } from '@/models';
import { C_FloorMaterial, C_SurfaceSelectedMaterial } from '@/shared/materials';
import {
  convertFlatVector3ToVectors,
  createGeometryFromVectorList,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import Border from '@/routes/dashboard/projects/project/UserBuilding/components/Border';
import { GenericChildSurface } from '@/models/building-nodes.model';

interface FloorProps extends GenericChildSurface {
  data: UserBuildingSurface;
  isLowestFloor?: boolean;
  blockGUID: string;
  isCeiling?: boolean;
}

const Surface: React.FC<FloorProps> = ({
  data,
  isParentSelected,
  isParentHovered,
  isParentLocked,
  blockGUID,
  isCeiling,
}) => {
  const { points } = data;
  const coordinates = useMemo(
    () => convertFlatVector3ToVectors(points),
    [data]
  );

  const surfaceGeometry = useMemo(() => {
    return createGeometryFromVectorList(coordinates, 'horizontal');
  }, [points]);

  const surfaceMaterial = useMemo(() => {
    return isParentSelected
      ? C_SurfaceSelectedMaterial.clone()
      : C_FloorMaterial.clone();
  }, [isParentSelected]);

  const userData = {
    userData: {
      ...data.userData,
      guid: blockGUID,
      isLocked: isParentLocked,
      nodeType: NodeType.Block,
      source: SelectedNodeSource.Viewer,
      originalBuildingBlock: {
        guid: blockGUID,
      },
    },
  };

  return (
    <mesh
      geometry={surfaceGeometry}
      material={surfaceMaterial}
      onPointerEnter={(e) => e.stopPropagation()}
      onPointerDown={(e) => e.stopPropagation()}
      {...(isCeiling ? userData : {})}
    >
      <Border
        geometry={surfaceGeometry}
        isSelected={isParentSelected}
        isHovered={isParentHovered}
      />
    </mesh>
  );
};

export default Surface;
