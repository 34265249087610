import React, { useMemo } from 'react';
import { UserBuildingBlock } from '@/models';
import { createLine2 } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import * as polyclip from 'polyclip-ts';
import {
  generateVerticalBlockContour,
  getBlockContour,
  getBlockContourMaterial,
  getEdgePoints,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';

const BlockContour = ({ block }: { block: UserBuildingBlock }) => {
  polyclip.setPrecision(1e-9);

  const verticalContour = useMemo(() => {
    return block.storeys
      .map((storey) =>
        getEdgePoints(storey).map((point) =>
          generateVerticalBlockContour(point, storey.ceiling.points[0])
        )
      )
      .flat();
  }, [block]);

  const contour = useMemo(() => getBlockContour(block), [block]);

  return (
    <group>
      {verticalContour.map((point, i) => (
        <primitive object={point} key={`vertical-contour_${i}`} />
      ))}

      {contour.map((edges, i) =>
        edges?.map((edge, j) => (
          <primitive
            object={createLine2(edge.flat(), getBlockContourMaterial())}
            key={`contour-point_${i}_${j}`}
          />
        ))
      )}
    </group>
  );
};

export default BlockContour;
