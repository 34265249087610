import React, { useEffect, useMemo, useRef, useState } from 'react';
import { WindowPlacementData } from '@/models';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import { FacadeDesignerModes, PointerPosition } from '@/models/shared.model';
import {
  FacadeDesignerPlacementType,
  getDragNode,
  getHoveredWall,
  getMeasurementActiveWall,
  getPlacementErrors,
  getSelectedPlacedWindows,
  getSelectedWindowFromLibrary,
  resetSelectedGridLines,
  setGridPlacementAbsoluteOffset,
  setHoveredWall,
  setMeasurementActiveWall,
  setPlacementError,
  setSelectedPlacedWindow,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { limitValue } from '@/shared/helpers/format-data';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { KonvaEventObject } from 'konva/lib/Node';
import { get2DDistance } from '@/shared/helpers/konva';
import {
  FlatVector2Axis,
  MeasurementElementType,
} from '@/components/WindowCreator/models';
import { round } from 'mathjs';
import { isLeftClick } from '@/shared/helpers';
import { Group } from 'react-konva';
import WindowView from '@/components/WindowView/WindowView';
import WallView from '@/components/FacadeDesigner/elements/WallView';

import { useFetchWindowsQuery } from '@/store/apis/windowApi';
import { useParams } from 'react-router';
import FacadeDesignerWindows from '@/components/FacadeDesigner/elements/FacadeDesignerWindows';
import MultiMeasurementLine from '@/shared/components/MultiMeasurementLine/MultiMeasurementLine';
import {
  useCustomElementPlacement,
  useFDElementValidation,
} from '@/components/FacadeDesigner/hooks';
import FacadeDesignerGridLines from '@/components/FacadeDesigner/elements/FacadeDesignerGridLines';
import { uuidv7 } from 'uuidv7';
import FacadeDesignerWallPanelsView from '@/components/FacadeDesigner/elements/FacadeDesignerWallPanelsView';
import PlacedWindowStates from '@/components/FacadeDesigner/elements/PlacedWindowStates';
import FacadeDesignerGridPlacement from '@/components/FacadeDesigner/elements/FacadeDesignerGridPlacement';
import { WindowPlacementDataForFD } from '../models';

interface FacadeDesignerFloorViewProps {
  wallOffset: { x: number; y: number };
  wallData: WallSearchResults;
  wallHeight: number;
  wallWidth: number;
  scale: number;
  onMeasurementUpdate: (data: WindowPlacementData[]) => void;
  onAddWindow: (data: WindowPlacementData[]) => void;
  facadeDesignerMode: FacadeDesignerModes;
  onGridPlacement: () => void;
  reportView?: boolean;
  handleWindowDragEnd?: (newOffset: number) => void;
}
const FacadeDesignerWallView = ({
  wallOffset,
  wallData,
  scale,
  wallHeight,
  wallWidth,
  onMeasurementUpdate,
  onAddWindow,
  facadeDesignerMode,
  onGridPlacement,
  reportView,
  handleWindowDragEnd,
}: FacadeDesignerFloorViewProps) => {
  const { id } = useParams();
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const windowsData = useFetchWindowsQuery(id!).data!;
  const hoveredWall = useAppSelector(getHoveredWall);
  const selectedWindows = useAppSelector(getSelectedPlacedWindows);
  const [measurementPoints, setMeasurementPoints] = useState<FlatVector2Axis[]>(
    []
  );
  const pointerPositionRef = useRef<PointerPosition | null>(null);
  const activeWall = useAppSelector(getMeasurementActiveWall);
  const selectedWindowFromLibrary = useAppSelector(
    getSelectedWindowFromLibrary
  );
  const dragNode = useAppSelector(getDragNode);
  const placementErrors = useAppSelector(getPlacementErrors);
  const hasAnyPlacementError = useMemo(
    () => Object.values(placementErrors).some((v) => v.state),
    [placementErrors]
  );

  const [placedWindowOffset, setPlacedWindowOffset] = useState<number>(-1);
  const dispatch = useAppDispatch();

  const isSelectionMode = facadeDesignerMode === FacadeDesignerModes.Selection;

  const isGridPlacementMode =
    facadeDesignerMode === FacadeDesignerModes.GridLinePlacement ||
    dragNode === FacadeDesignerPlacementType.GridLine;

  const isWindowPlacementProcessing = !!selectedWindowFromLibrary;

  const placedWindows: WindowPlacementDataForFD[] = useMemo(() => {
    return (
      wallData.windowPlacements.map((windowData) => ({
        ...windowData,
        wallGUID: wallData.guid,
      })) || []
    );
  }, [wallData]);

  const handleMouseEnter = () => {
    dispatch(setHoveredWall(wallData.guid));
  };

  const { validateWindowPlacement } = useFDElementValidation(
    wallData,
    wallWidth,
    placedWindowOffset,
    selectedWindows
  );

  useEffect(() => {
    !hoveredWall && !activeWall && resetMeasurementTool();

    hoveredWall === wallData.guid &&
      !activeWall &&
      pointerPositionRef.current &&
      updateMeasurementTool(pointerPositionRef.current);
  }, [activeWall, hoveredWall, pointerPositionRef.current]);

  useEffect(() => {
    setMeasurementPoints([]);
  }, [facadeDesignerMode]);

  const handleMouseLeave = () => {
    !activeWall && dispatch(setGridPlacementAbsoluteOffset(0));
    !activeWall &&
      dispatch(setPlacementError({ key: wallData.guid, state: false }));
    dispatch(setHoveredWall(null));
  };

  const onWindowPlacement = () => {
    if (!selectedWindowFromLibrary) return;
    const placements: WindowPlacementData[] = [
      ...placedWindows,
      {
        windowId: selectedWindowFromLibrary.id,
        offsetFromLeftEdge: placedWindowOffset,
        guid: uuidv7(),
      },
    ];
    onAddWindow(placements);
  };

  // Should be pointer down event, to go faster than cancelling active measurement
  const handlePointerDown = (event: KonvaEventObject<PointerEvent>) => {
    if (activeWall || !isLeftClick(event.evt) || hasAnyPlacementError) return;
    if (isWindowPlacementProcessing && measurementPoints?.length) {
      onWindowPlacement();
    } else if (isGridPlacementMode && measurementPoints?.length) {
      onGridPlacement();
    }
    setMeasurementPoints([]);
  };

  const { handleGridMovePlacement, handleWindowMovePlacement } =
    useCustomElementPlacement({
      wallData,
      yPosition: wallHeight / 2,
    });

  const updateMeasurementTool = (pointerPosition: PointerPosition) => {
    const roundedPointerPosition = {
      x: round(pointerPosition.x, 0),
      y: round(pointerPosition.y, 0),
    };
    if (isWindowPlacementProcessing) {
      const windowWidth = get2DDistance(
        selectedWindowFromLibrary.points[0],
        selectedWindowFromLibrary.points[1]
      );

      const maxXPosition = limitValue(wallWidth - windowWidth, 0, wallWidth);
      const xPosition = roundedPointerPosition.x - windowWidth / 2;

      const offset = limitValue(xPosition, 0, maxXPosition);
      setPlacedWindowOffset(offset);
      setMeasurementPoints(
        handleWindowMovePlacement(offset, selectedWindowFromLibrary)
      );
    } else if (isGridPlacementMode) {
      const offset = round(wallOffset.x + roundedPointerPosition.x, 0);
      dispatch(setGridPlacementAbsoluteOffset(offset));
      setMeasurementPoints(handleGridMovePlacement(roundedPointerPosition.x));
    }
  };

  const handleMouseMove = (event: KonvaEventObject<MouseEvent>) => {
    const pointerPosition = event.currentTarget?.getRelativePointerPosition();

    if (!pointerPosition) return;
    pointerPositionRef.current = pointerPosition;

    if (activeWall) return;
    updateMeasurementTool(pointerPosition);
  };

  const handleActiveMeasurementStatus = (isActive: boolean) => {
    dispatch(setMeasurementActiveWall(isActive ? wallData.guid : null));
  };

  const handleMeasurementEscape = () => {
    dispatch(setMeasurementActiveWall(null));
  };

  const handleMeasurementChange = (points: FlatVector2Axis[]) => {
    setMeasurementPoints(points);

    if (isGridPlacementMode) {
      dispatch(
        setGridPlacementAbsoluteOffset(round(points[1][0][0] + wallOffset.x, 2))
      );
      setMeasurementPoints(handleGridMovePlacement(points[1][0][0]));
    } else if (isWindowPlacementProcessing) {
      setPlacedWindowOffset(points[0][1][0]);
      setMeasurementPoints(
        handleWindowMovePlacement(points[0][1][0], selectedWindowFromLibrary)
      );
    }
  };

  const resetMeasurementTool = () => {
    setPlacedWindowOffset(-1);
    dispatch(setMeasurementActiveWall(null));
    dispatch(setGridPlacementAbsoluteOffset(0));
    pointerPositionRef.current = null;
    setMeasurementPoints([]);
  };

  const handleMeasurementSubmit = () => {
    if (isGridPlacementMode) {
      onGridPlacement();
    } else if (isWindowPlacementProcessing) {
      onWindowPlacement();
    }
    resetMeasurementTool();
  };

  const resetSelectedWindow = (e: KonvaEventObject<MouseEvent>) => {
    if (!isSelectionMode || !isLeftClick(e.evt)) return;
    e.cancelBubble = true;
    dispatch(setSelectedPlacedWindow([]));
    dispatch(resetSelectedGridLines());
  };

  useEffect(() => {
    selectedWindowFromLibrary &&
      placedWindowOffset >= 0 &&
      [activeWall, hoveredWall].includes(wallData.guid) &&
      validateWindowPlacement(selectedWindowFromLibrary);
  }, [placedWindowOffset, selectedWindowFromLibrary, activeWall, hoveredWall]);

  useEffect(() => {
    setMeasurementPoints([]);
  }, [dragNode]);

  const handleWindowStartDragPosition = () => {
    if (!selectedWindowFromLibrary || !pointerPositionRef.current) return;
    const windowWidth = get2DDistance(
      selectedWindowFromLibrary.points[0],
      selectedWindowFromLibrary.points[1]
    );

    const roundedPointerPosition = {
      x: round(pointerPositionRef.current.x, 0),
      y: round(pointerPositionRef.current.y, 0),
    };
    const maxXPosition = limitValue(wallWidth - windowWidth, 0, wallWidth);
    const xPosition = roundedPointerPosition.x - windowWidth / 2;

    const offset = limitValue(xPosition, 0, maxXPosition);
    setPlacedWindowOffset(offset);
  };

  useEffect(() => {
    dragNode === FacadeDesignerPlacementType.Window &&
      handleWindowStartDragPosition();
  }, [dragNode, selectedWindowFromLibrary]);

  const handlePointerUp = (e: KonvaEventObject<PointerEvent>) => {
    if (hasAnyPlacementError) return;
    if (dragNode === FacadeDesignerPlacementType.Window) {
      e.cancelBubble = true;
      handleWindowDragEnd && handleWindowDragEnd(placedWindowOffset);
    }
  };

  return (
    <Group
      offsetX={-wallOffset.x}
      offsetY={wallOffset.y}
      onPointerDown={handlePointerDown}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onPointerUp={handlePointerUp}
    >
      <Group onClick={resetSelectedWindow}>
        <WallView
          wallWidth={wallWidth}
          wallHeight={wallHeight}
          scale={scale}
          reportView={reportView}
        />
      </Group>

      <FacadeDesignerWindows
        placedWindows={placedWindows}
        scale={scale}
        windowsData={windowsData}
        unitSystem={unitSystem}
        wallData={wallData}
        isWindowPlacementProcessing={isWindowPlacementProcessing}
        wallHeight={wallHeight}
        wallWidth={wallWidth}
        facadeDesignerMode={facadeDesignerMode}
        onMeasurementUpdate={onMeasurementUpdate}
      />
      <FacadeDesignerWallPanelsView
        wallData={wallData}
        scale={scale}
        wallHeight={wallHeight}
        unitSystem={unitSystem}
        reportView={reportView}
      />

      <FacadeDesignerGridLines
        scale={scale}
        unitSystem={unitSystem}
        wallHeight={wallHeight}
        wallWidth={wallWidth}
        wallData={wallData}
        xWallOffset={wallOffset.x}
        reportView={reportView}
      />

      {isGridPlacementMode && (
        <FacadeDesignerGridPlacement
          scale={scale}
          wallData={wallData}
          wallHeight={wallHeight}
          wallWidth={wallWidth}
          xWallOffset={wallOffset.x}
          unitSystem={unitSystem}
        />
      )}

      {isWindowPlacementProcessing &&
        (activeWall
          ? activeWall === wallData.guid
          : hoveredWall === wallData.guid) && (
          <Group opacity={0.75} listening={false}>
            <WindowView
              data={selectedWindowFromLibrary}
              scale={scale}
              units={unitSystem}
              offsetX={placedWindowOffset}
              offsetY={wallHeight - selectedWindowFromLibrary.distanceToFloor}
              viewOnly
            />
            <PlacedWindowStates
              data={selectedWindowFromLibrary}
              isHovered={false}
              isSelected={false}
              hasError={hasAnyPlacementError}
              offsetX={placedWindowOffset}
              offsetY={wallHeight - selectedWindowFromLibrary.distanceToFloor}
            />
          </Group>
        )}

      {!!measurementPoints?.length &&
        !dragNode &&
        (activeWall
          ? activeWall === wallData.guid
          : hoveredWall === wallData.guid) && (
          <MultiMeasurementLine
            multiPoints={measurementPoints}
            scale={scale}
            units={unitSystem}
            type={
              isGridPlacementMode
                ? MeasurementElementType.GridDistance
                : MeasurementElementType.WindowDistance
            }
            onActiveStatusChange={handleActiveMeasurementStatus}
            onEscape={handleMeasurementEscape}
            onChange={handleMeasurementChange}
            onSubmit={handleMeasurementSubmit}
            customErrorMessage={
              Object.values(placementErrors).find((err) => err.state)?.message
            }
          />
        )}
    </Group>
  );
};

export default FacadeDesignerWallView;
