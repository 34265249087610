import React, { useEffect, useMemo, useState } from 'react';
import { hideSelectionBoxArea, isLeftClick } from '@/shared/helpers';
import { RootState, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { useParams } from 'react-router';
import {
  generateEdgePoint,
  pointTargetOnMap,
  PROJECT_CANVAS_ID,
} from '@/shared/helpers/canvas-verifiers';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getMapUUID } from '@/store/slices/canvasMapSlice';

import {
  convertBufferGeometryTo3DVectorList,
  createLine2,
  getDistanceMillimeters,
  getExtendedVector,
  getGeometryPointByIdx,
  getXYZ,
  setObjectPosition,
  triangulateGeometryAndUpdate,
  updateLine2Position,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  C_DashedFatLineContourMaterial,
  C_FatLineContourMaterial,
  C_FloorMaterial,
} from '@/shared/materials';
import {
  getProcessingEntity,
  resetExternalElementsState,
  setDirectionalInputValues,
  setShowDirectionalInput,
} from '@/store/slices/canvasExternalElementsSlice';
import { publish, subscribe, unsubscribe } from '@/core/events';
import {
  DIRECTIONAL_INPUT__SET,
  DIRECTIONAL_INPUT__UPDATE,
  SET_BUILDING_CREATION_PARAMETERS,
} from '@/core/event-names';
import { convertMillimetersToMeters } from '@/shared/helpers/distance';
import {
  DistanceInput,
  FlatVector3,
  MetricLimits,
  NumberedInput,
} from '@/models';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import ScalableDot from '@/shared/components/Dot/ScalableDot';
import {
  FLOORS_INITIAL_COUNT,
  INITIAL_FLOOR_HEIGHT_IN_MILLIMETERS,
} from '@/shared/constants';
import { getMultiplyRate } from '@/store/slices/projectSlice';
import { getIsCameraRotating } from '@/store/slices/canvasCamerasSlice';

const MAX_POINTS = 50;

const FreeDrawMode = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const createdBuildingFromStore = !!useFetchProjectQuery(id!).data?.buildings
    ?.length;

  const mapUUID = useAppSelector(getMapUUID);
  const processingEntity = useAppSelector(getProcessingEntity)!;
  const isDirectionalInputActive = processingEntity.active;
  const [pointerPosition, setPointerPosition] = useState<THREE.Vector3 | null>(
    null
  );
  const [contour, setContour] = useState<THREE.Line>(null!);
  const [fatContour, setFatContour] = useState<Line2[] | null>(null);
  const [closingLine, setClosingLine] = useState<Line2 | null>(null);
  const [dynamicLine, setDynamicLine] = useState<Line2>(null!);
  const [lastPoint, setLastPoint] = useState<FlatVector3>([0, 0, 0]);
  const [startPoint, setStartPoint] = useState<FlatVector3>([0, 0, 0]);

  const [floorShape, setFloorShape] = useState<THREE.Mesh>(null!);
  const [finishedBuilding, setFinishedBuilding] = useState<THREE.Mesh | null>(
    null
  );
  const [nextPoint, setNextPoint] = useState<THREE.Mesh>(null!);

  const [isDrawing, setIsDrawing] = useState(false);
  const [isBuildingFinished, setIsBuildingFinished] = useState(false);
  const isCameraRotating = useAppSelector(getIsCameraRotating);

  const scene: RootState = useThree();
  const edges: THREE.Vector3[] = useMemo(() => [], []);
  const edgeOnPointer = useMemo(() => {
    const liveEdge = generateEdgePoint();
    !createdBuildingFromStore && setNextPoint(liveEdge);
    return liveEdge;
  }, [createdBuildingFromStore]);

  const multiplyRate = useAppSelector(getMultiplyRate(id!));
  const buildingFloorMaterial = useMemo(() => C_FloorMaterial.clone(), []);

  const updateDirectionalInputValueForDistance = (
    pointOnMap: THREE.Vector3
  ) => {
    const distance = getDistanceMillimeters(
      getLastAddedPoint().distanceTo(pointOnMap),
      multiplyRate
    );

    dispatch(
      setDirectionalInputValues([
        {
          ...processingEntity,
          value: distance.toString(),
        },
      ])
    );
  };

  const addNextPoint = (point: THREE.Vector3) => {
    if (!contour) {
      const geometry = new THREE.BufferGeometry();
      const positions = new Float32Array(MAX_POINTS * 3); // 3 vertices per point
      geometry.setAttribute(
        'position',
        new THREE.BufferAttribute(positions, 3)
      );

      const geometryPosition = geometry.getAttribute('position');

      geometryPosition.setXYZ(0, ...getXYZ(point));
      geometryPosition.setXYZ(1, ...getXYZ(point));

      geometry.setDrawRange(0, 2);

      setLastPoint(getXYZ(point));
      setStartPoint(getXYZ(point));

      const line = new THREE.Line(geometry, C_DashedFatLineContourMaterial);
      setContour(line);

      setDynamicLine(
        createLine2(
          [...getXYZ(point), ...getXYZ(point)],
          C_DashedFatLineContourMaterial
        )
      );

      setIsDrawing(true);
      dispatch(
        setShowDirectionalInput({
          isShow: true,
        })
      );
      dispatch(
        setDirectionalInputValues([
          {
            type: DistanceInput.Distance,
            display: true,
            processing: true,
            min: MetricLimits.WidthLengthMin,
            max: MetricLimits.WidthLengthMax,
          },
        ])
      );
    } else if (isDrawing) {
      if (pointerNearFirstPoint(point)) {
        closeFirstFloor();
        return;
      }

      if (edges.length === 1) {
        setDynamicLine(
          createLine2(
            [...getXYZ(point), ...getXYZ(point)],
            C_FatLineContourMaterial
          )
        );
      }

      const contourPosition = contour.geometry.getAttribute('position');

      contourPosition.setXYZ(edges.length, ...getXYZ(point));
      contourPosition.setXYZ(edges.length + 1, ...getXYZ(point));
      contour.geometry.setDrawRange(0, ((edges.length + 1) % MAX_POINTS) + 1);
      contourPosition.needsUpdate = true;
      setFirstFloor(true);

      //FAT LINE CONTOUR
      setFatContour((prev) => [
        ...(prev ? prev : []),
        createLine2([...lastPoint, ...getXYZ(point)], C_FatLineContourMaterial),
      ]);
      setLastPoint(getXYZ(point));
      if (!dynamicLine) {
        setDynamicLine(
          createLine2(
            [...getXYZ(point), ...getXYZ(point)],
            C_FatLineContourMaterial
          )
        );
      }

      updateDirectionalInputValueForDistance(point);
      dispatch(
        setDirectionalInputValues([{ ...processingEntity, active: false }])
      );
    }

    edges.push(point);
    if (finishedBuilding) {
      setIsBuildingFinished(true);
    }
  };

  const onPointerDown = (event: PointerEvent) => {
    hideSelectionBoxArea();
    if (!isLeftClick(event) || createdBuildingFromStore) return;

    if (!pointTargetOnMap(event, scene, mapUUID)) return;
    addNextPoint(nextPoint.position);
  };

  const closeFirstFloor = () => {
    if (edges.length < 3) return;

    const position = contour.geometry.getAttribute('position');
    position.setXYZ(
      edges.length,
      position.getX(0),
      position.getY(0),
      position.getZ(0)
    );
    contour.geometry.setDrawRange(0, (edges.length + 1) % MAX_POINTS);
    position.needsUpdate = true;

    if (floorShape) {
      const floorPosition = floorShape.geometry.getAttribute('position');
      const fixedY = floorPosition.getY(0);
      for (let i = 0; i < floorPosition.count; i++) {
        floorPosition.setY(i, fixedY);
      }
      floorPosition.needsUpdate = true;
    }

    (floorShape.material as THREE.Material).transparent = false;
    (floorShape.material as THREE.Material).needsUpdate = true;
    setIsDrawing(false);

    setFinishedBuilding(floorShape);
    publish(SET_BUILDING_CREATION_PARAMETERS, { floorShape });
    !!floorShape &&
      dispatch(
        setDirectionalInputValues([
          {
            type: NumberedInput.Floors,
            value: FLOORS_INITIAL_COUNT.toString(),
            processing: true,
            display: true,
          },
          {
            type: DistanceInput.FloorHeight,
            value: INITIAL_FLOOR_HEIGHT_IN_MILLIMETERS.toString(),
            display: true,
          },
          {
            type: DistanceInput.Distance,
            display: false,
            min: null,
            max: null,
          },
        ])
      );
  };

  const resetDraw = () => {
    setIsDrawing(false);
    setIsBuildingFinished(false);
    setFinishedBuilding(null!);
    dispatch(resetExternalElementsState());
    setContour(null!);
    setFloorShape(null!);
    setDynamicLine(null!);
    edges.length = 0;
  };

  const onKeydown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Enter': {
        if (isDrawing) {
          setObjectPosition(nextPoint, getXYZ(getStartingPoint()));
          updateBorders(getStartingPoint());
          setPointerPosition(getStartingPoint());
          closeFirstFloor();
        }
        return;
      }
      case 'Escape': {
        resetDraw();
        return;
      }
    }
  };

  const setFirstFloor = (create: boolean = false) => {
    const floorGeometry = create
      ? new THREE.ShapeGeometry()
      : floorShape.geometry;
    const vectors: THREE.Vector3[] = convertBufferGeometryTo3DVectorList(
      contour.geometry
    );
    floorGeometry.setFromPoints(vectors);
    floorGeometry.setAttribute(
      'position',
      new THREE.Float32BufferAttribute(
        vectors.map((point) => [...getXYZ(point)]).flat(),
        3
      )
    );
    triangulateGeometryAndUpdate(floorGeometry, vectors);

    if (create) {
      const newFloorShape = new THREE.Mesh(
        floorGeometry,
        buildingFloorMaterial
      );
      if (floorShape) {
        scene.scene.remove(floorShape);
      }

      setFloorShape(newFloorShape);
    }
  };

  const pointerNearFirstPoint = (pointOnMap: THREE.Vector3) => {
    return floorShape && pointOnMap.distanceTo(getStartingPoint()) < 0.02;
  };

  const getStartingPoint = () =>
    new THREE.Vector3(...getGeometryPointByIdx(0, contour.geometry));

  const getLastAddedPoint = () =>
    new THREE.Vector3(
      ...getGeometryPointByIdx(
        contour.geometry.drawRange.count - 2,
        contour.geometry
      )
    );

  const getAddPoint = () =>
    new THREE.Vector3(
      ...getGeometryPointByIdx(
        contour.geometry.drawRange.count - 1,
        contour.geometry
      )
    );

  const onPointerMove = (event: PointerEvent) => {
    const pointOnMap = pointTargetOnMap(event, scene, mapUUID);

    if (!pointOnMap) return;

    setPointerPosition(pointOnMap);

    if (!isDirectionalInputActive) {
      setObjectPosition(nextPoint, getXYZ(pointOnMap));

      if (contour) {
        const distance = getDistanceMillimeters(
          getLastAddedPoint().distanceTo(pointOnMap),
          multiplyRate
        );

        const extendedVector = getExtendedVector(
          getLastAddedPoint(),
          pointOnMap,
          convertMillimetersToMeters(
            Math.max(
              MetricLimits.WidthLengthMin,
              Math.min(MetricLimits.WidthLengthMax, distance)
            )
          ) * multiplyRate
        );

        setObjectPosition(nextPoint, getXYZ(extendedVector));
      }
    } else {
      const extendedVector = getExtendedVector(
        getLastAddedPoint(),
        pointerPosition!,
        convertMillimetersToMeters(processingEntity.value) * multiplyRate
      );
      setObjectPosition(nextPoint, getXYZ(extendedVector));
    }

    if (pointerNearFirstPoint(pointOnMap)) {
      nextPoint.position.set(...getXYZ(getStartingPoint()));
      updateDirectionalInputValueForDistance(getStartingPoint());
      dispatch(
        setDirectionalInputValues([{ ...processingEntity, active: false }])
      );
    } else if (!isDirectionalInputActive) {
      contour && updateDirectionalInputValueForDistance(getAddPoint());
    }
  };

  const updateDynamicLine = (point: THREE.Vector3) => {
    if (dynamicLine) {
      updateLine2Position(dynamicLine, [...lastPoint, ...getXYZ(point)]);
    }
  };

  const updateBorders = (point: THREE.Vector3) => {
    const contourPosition = contour.geometry.getAttribute('position');

    if (floorShape && point.distanceTo(getStartingPoint()) < 0.02) {
      contourPosition.setXYZ(edges.length, ...getXYZ(getStartingPoint()));
      contour.geometry.getAttribute('position').needsUpdate = true;
    } else {
      contourPosition.setXYZ(edges.length, ...getXYZ(point));
      contourPosition.needsUpdate = true;
    }

    setFirstFloor(!floorShape);
    updateDynamicLine(point);
    updateClosingLine(point);
  };

  const updateClosingLine = (point: THREE.Vector3) => {
    if (!closingLine) {
      setClosingLine(
        createLine2(
          [...getXYZ(point), ...getXYZ(point)],
          C_DashedFatLineContourMaterial
        )
      );
    } else {
      updateLine2Position(closingLine, [...startPoint, ...getXYZ(point)]);
    }
  };

  const drawBorderLine = (event: PointerEvent) => {
    if (!pointTargetOnMap(event, scene, mapUUID)) return;
    updateBorders(nextPoint.position);
  };

  const onInputUpdate = (evt: CustomEvent) => {
    if (isDrawing) {
      const extendedVector = getExtendedVector(
        getLastAddedPoint(),
        pointerPosition!,
        convertMillimetersToMeters(evt.detail) * multiplyRate
      );
      setObjectPosition(nextPoint, getXYZ(extendedVector));
      updateBorders(extendedVector);
    }
  };

  const onInputSet = (evt: CustomEvent) => {
    if (isDrawing) {
      const extendedVector = getExtendedVector(
        getLastAddedPoint(),
        pointerPosition!,
        convertMillimetersToMeters(evt.detail) * multiplyRate
      );
      setObjectPosition(nextPoint, getXYZ(extendedVector));
      addNextPoint(extendedVector);
      updateDynamicLine(extendedVector);
      updateClosingLine(extendedVector);
    }
  };

  useEffect(() => {
    subscribe(DIRECTIONAL_INPUT__SET, onInputSet);
    subscribe(DIRECTIONAL_INPUT__UPDATE, onInputUpdate);
    return () => {
      unsubscribe(DIRECTIONAL_INPUT__SET, onInputSet);
      unsubscribe(DIRECTIONAL_INPUT__UPDATE, onInputUpdate);
    };
  }, [
    edges,
    contour,
    isDrawing,
    finishedBuilding,
    floorShape,
    dynamicLine,
    edgeOnPointer,
    pointerPosition,
  ]);

  useEffect(() => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID)!;

    if (!canvas) return;

    const isAbleToMove = !finishedBuilding && !isCameraRotating;
    const isAbleToDraw = !isCameraRotating && isDrawing;
    const isAbleToUseKeydown =
      ((isDrawing && contour) || finishedBuilding) && !isCameraRotating;

    !isCameraRotating && canvas.addEventListener('pointerdown', onPointerDown);
    isAbleToUseKeydown && document.addEventListener('keydown', onKeydown);
    isAbleToMove && canvas.addEventListener('pointermove', onPointerMove);
    isAbleToDraw && canvas.addEventListener('pointermove', drawBorderLine);

    return () => {
      document.removeEventListener('keydown', onKeydown);
      canvas.removeEventListener('pointerdown', onPointerDown);
      canvas.removeEventListener('pointermove', drawBorderLine);
      canvas.removeEventListener('pointermove', onPointerMove);
    };
  }, [
    contour,
    isDrawing,
    isBuildingFinished,
    floorShape,
    dynamicLine,
    edgeOnPointer,
    pointerPosition,
    isDirectionalInputActive,
    processingEntity.value,
    fatContour,
    lastPoint,
    startPoint,
    closingLine,
    isCameraRotating,
  ]);

  return (
    <>
      {pointerPosition && (
        <ScalableDot dotPosition={edgeOnPointer.position.clone()} />
      )}
      {contour && <primitive object={contour} />}
      {dynamicLine && <primitive object={dynamicLine} />}
      {closingLine && <primitive object={closingLine} />}
      {floorShape && (
        <primitive
          object={floorShape}
          position={new THREE.Vector3(0, 0.001, 0)}
        />
      )}
      {fatContour &&
        fatContour.map((contourLine) => {
          return <primitive object={contourLine} key={contourLine.id} />;
        })}
      {edges.length > 0 &&
        edges.map((edge, i) => <ScalableDot dotPosition={edge} key={i} />)}
    </>
  );
};

export default FreeDrawMode;
