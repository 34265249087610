import React from 'react';
import { Line } from 'react-konva';
import { GridLineConfig } from '../models';
import { DISABLED_ELEMENT_COLOR } from '@/components/WindowCreator/constants';

interface WallViewProps {
  wallWidth: number;
  wallHeight: number;
  scale: number;
  reportView?: boolean;
}

const WallView: React.FC<WallViewProps> = ({
  wallWidth,
  wallHeight,
  scale,
  reportView,
}) => {
  const wallPoints = [
    [0, 0],
    [wallWidth, 0],
    [wallWidth, wallHeight],
    [0, wallHeight],
  ].flat();

  return (
    <>
      <Line
        points={wallPoints}
        fill={'#fff'}
        closed
        stroke={
          reportView ? DISABLED_ELEMENT_COLOR : GridLineConfig.placed.stroke
        }
        strokeWidth={reportView ? 1 : 1.3 / scale}
        strokeScaleEnabled={!reportView}
      />
    </>
  );
};

export default WallView;
